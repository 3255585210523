import { GAPageType } from '../GAhelpers';

/**
 * This function is typically invoked when the user logins to the website.
 */

export const AmplitudeEvent_login = () => {
  try {
    window.dataLayer.push({
      'Event Name': 'Login Completed',
      'login trigger': GAPageType() || '',
    });
  } catch {}
};

/**
 * This function is typically invoked when the new user registers to the website.
 */

export const AmplitudeEvent_register = () => {
  try {
    window.dataLayer.push({
      'Event Name': 'Account Registration Completed',
      'login trigger': GAPageType() || '',
    });
  } catch {}
};
