/* eslint-disable camelcase */
/* eslint-disable no-return-await */
import Router from 'next/router';
import fetchApi from '@/src/Middleware/fetchApi';
import { loadCleverTap } from '@/src/thirdPartyScripts';
import { decrypt } from '../../src/Middleware/helpers';
import { getCookies, removeCookie } from '../../src/Utils/Cookies';
import { fetchWLWithStock, fetchWLWithoutStock } from './WishlistPage';
import { fetchCart } from './PaymentPageActions';
import locationPrompt from './LocationPrompt';
import { showToastMessage } from './toastMessageActions';
import { createWallet } from './WalletPage';
import { AmplitudeEvent_login } from '@/redux/actions/GAEventsActions/globalEvents/loginAndRegistrationEvent';
import { DY_Login, DY_Signup } from '@/src/lib/dynamicYield/events';

const fetchUserData = () => {
  return async (dispatch) => {
    await fetchApi(null, `/api/dktlogin/get-user-details`);
    removeCookie('dkt_fetch_user_details');
  };
};

const checkLoginStatus = () => {
  return async (dispatch) => {
    const params = new URLSearchParams(window.location.search);
    const isLoggedIn = params.get('isLoggedIn');
    const { pathname } = window.location;
    if (params.get('isLoggedIn')) {
      if (isLoggedIn === 'true') {
        dispatch(
          showToastMessage({
            appearance: 'success',
            type: 'toast',
            message: 'Welcome to Decathlon India!',
            timeout: 3000,
          }),
        );
        loadCleverTap();
        const isCartPage = Router.router && Router.router.pathname === '/cart';
        if (isCartPage) {
          dispatch({
            type: 'TOGGLE_CART_LOADER',
            payload: true,
          });
        }
        try {
          const response = await fetchApi(null, `/api/dktlogin/get-user-details`);
          if (response?.status) {
            const {
              dkt_mn: mobile,
              dkt_em: email,
              dkt_ufn: firstName,
              dkt_loyaltyCard,
              dkt_cartId: cartId,
              dkt_pincode: pincode,
            } = getCookies();
            dispatch({
              type: 'TRIGGER_CHATBOT_LOGIN',
              payload: true,
            });
            window.dataLayer.push({
              event: isCartPage ? 'SignInProceed' : 'SignInSuccess',
              loyalty_id: decrypt(dkt_loyaltyCard),
            });
            AmplitudeEvent_login();
            if (typeof clevertap !== 'undefined' && email && mobile) {
              clevertap.onUserLogin.push({
                Site: {
                  Email: decrypt(email),
                  Identity: decrypt(dkt_loyaltyCard),
                  Name: decrypt(firstName),
                  Phone: decrypt(mobile),
                  'MSG-email': true, // Disable email notifications
                  'MSG-push': true, // Enable push notifications
                  'MSG-sms': true, // Enable sms notifications
                },
              });
            }

            const { dkt_memberId: memberId } = getCookies();

            if (response?.collectUserInfo) {
              DY_Signup({ cuid: decrypt(memberId) });
              dispatch({ type: 'MY_PROFILE_POPUP' });
            } else {
              DY_Login({ cuid: decrypt(memberId) });
            }

            const isPaymentPage = Router.router && Router.router?.state?.pathname === '/payment';

            if (isPaymentPage) {
              await dispatch(fetchCart());
              return false;
            }

            // call wishlist API
            const customerId = decrypt(dkt_loyaltyCard);
            const isWishlistPage = Router.router && Router.router.pathname === '/wishlist';
            if (customerId) {
              if (!isWishlistPage) {
                dispatch(fetchWLWithoutStock());
              } else {
                dispatch(fetchWLWithStock());
              }
            }

            let cartPincode = pincode;

            if (cartId) {
              const mergeResponse = await fetchApi(null, `/api/cart/merge`);
              if (mergeResponse?.status) {
                cartPincode = mergeResponse?.cart?.postalCode || pincode;
                dispatch({
                  type: 'GET_CART',
                  payload: mergeResponse?.cart,
                });
                dispatch({
                  type: 'GET_CART_COUNT',
                  payload: mergeResponse?.cart?.totalLineCount,
                });
              }
            } else {
              const cartResponse = await fetchApi(null, `/api/cart`);
              const { cartLines } = cartResponse?.cart;
              cartPincode = cartResponse?.cart?.postalCode || pincode;
              if (cartLines) {
                dispatch({
                  type: 'GET_CART',
                  payload: cartResponse?.cart,
                });
              }
            }

            dispatch(createWallet());

            if (Number(pincode) !== Number(cartPincode)) {
              await dispatch(
                locationPrompt.updatePincode({
                  pincode: cartPincode,
                }),
              );
            }
          }
        } catch (error) {}
        if (isCartPage) {
          dispatch({
            type: 'TOGGLE_CART_LOADER',
            payload: false,
          });
        }
      } else if (isLoggedIn !== 'true') {
        dispatch(
          showToastMessage({
            appearance: 'error',
            type: 'toast',
            message: params.get('message') || 'Login failed.',
            timeout: 2000,
          }),
        );
      }

      params.delete('isLoggedIn');
      params.delete('message');

      window.history.pushState({}, document.title, `${pathname}${params?.size ? `?${params.toString()}` : ''}`);
    }
  };
};

const toggleMyProfilePopup = () => {
  return (dispatch) => {
    return dispatch({ type: 'MY_PROFILE_POPUP' });
  };
};

export default {
  checkLoginStatus,
  toggleMyProfilePopup,
  fetchUserData,
};
