/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable no-console */
/* eslint-disable no-return-await */
import fetchApi from '@/src/Middleware/fetchApi';
import { getCorrectDate, getPlatform, getContentfulClientEntries } from '@/src/Utils';
import { appSettingContent, sellerInformationContent } from '@/constant/contentfulConstants';

const getDigitalOrders = ({ startDate, endDate } = false) => {
  return async (dispatch) => {
    dispatch({ type: 'DIGITAL_ORDERS', payload: 'No Data' });
    const platform = getPlatform();

    const digitalOrders = await fetchApi(null, `/api/customer/digitalorders`, {
      data: {
        startDate,
        endDate,
      },
    });
    if (digitalOrders?.status) {
      // Condition added for shop in shop PH
      if (digitalOrders?.data?.orders?.length > 0 && (platform === 'PAYTM_MINI' || platform === 'PHONEPE_SWITCH')) {
        digitalOrders.data.orders = digitalOrders.data.orders.filter((order) => order.channelId === platform);
      }
      dispatch({
        type: 'DIGITAL_ORDERS',
        payload: digitalOrders?.data,
      });
    } else {
      dispatch({ type: 'DIGITAL_ORDERS', payload: false });
    }
    return digitalOrders;
  };
};

const getStoreOrders = (nextPage = false) => {
  return async (dispatch) => {
    if (!nextPage) {
      dispatch({ type: 'STORE_ORDERS', payload: 'No Data' });
    }

    const storeOrders = await fetchApi(null, `/api/customer/storeorders`, {
      data: {
        range: '0-50',
      },
    });
    if (storeOrders?.status) {
      if (!nextPage) {
        if (storeOrders?.data !== '') {
          dispatch({
            type: 'STORE_ORDERS',
            payload: storeOrders?.data,
          });
        } else {
          dispatch({
            type: 'STORE_ORDERS',
            payload: 'No Orders',
          });
        }
      }
    } else {
      dispatch({ type: 'STORE_ORDERS', payload: false });
    }
    return storeOrders;
  };
};

// EmployeeDiscount

const getEmployeeDiscountDetails = () => {
  return async (dispatch) => {
    dispatch({
      type: 'EMPLOYEE_DISCOUNT_LOADER',
      payload: true,
    });

    const apiResponse = await fetchApi(null, `/api/employee-discount/employee-discount-details`, {
      data: {},
    });

    dispatch({
      type: 'EMPLOYEE_DISCOUNT',
      payload: { ...apiResponse?.discountDetails, status: apiResponse?.status },
    });

    return apiResponse;
  };
};

const getOrderTrackDetails = ({ isDecathlon, orderPartId, shippingId, returnId }) => {
  return async (dispatch) => {
    dispatch({ type: 'ORDER_TRACK_DETAILS', payload: 'No Data' });
    let response;

    if (isDecathlon) {
      response = await fetchApi(null, `/api/customer/getTrackDetails-repex`, {
        data: { id: orderPartId, returnId: returnId || 0 },
      });
    } else {
      response = await fetchApi(null, `/api/customer/getTrackDetails`, {
        data: { id: shippingId, isDecathlon },
      });
    }
    if (response?.status) {
      dispatch({
        type: 'ORDER_TRACK_DETAILS',
        payload: response?.data,
      });
    } else {
      dispatch({ type: 'ORDER_TRACK_DETAILS', payload: false });
    }
    return response;
  };
};

const checkReturnService = ({ itemId, quantity, price, postalCode, additionalWeight, weight, locationId }) => {
  const payload = {
    return_options: {
      fulfiller: {
        items: [
          {
            additional_packaging_weight: additionalWeight || 0,
            additional_shipping_fee: 0,
            dimension: {
              height: 1,
              length: 1,
              width: 1,
            },
            ean: 'E',
            item_id: itemId,
            price,
            quantity,
            type: 'sport',
            weight: weight || 1,
          },
        ],
        location: locationId,
      },
      source_postalcode: postalCode,
    },
  };

  return async () => {
    return fetchApi(null, `/api/customer/serviceability`, {
      data: {
        payload,
      },
    });
  };
};

const getCancelReasons = ({ orderPartId }) => {
  return async (dispatch) => {
    dispatch({ type: 'CANCEL_REASONS', payload: 'No Data' });
    const response = await fetchApi(null, `/api/customer/getCancelReasons`, {
      data: { orderPartId },
    });
    if (response?.status) {
      dispatch({
        type: 'CANCEL_REASONS',
        payload: response?.reasons,
      });
    } else {
      dispatch({ type: 'CANCEL_REASONS', payload: false });
    }
    return response;
  };
};

const cancelOrder = ({ orderPartId, reason, refundMode, payoutId = null }) => {
  return async (dispatch) => {
    return fetchApi(null, `/api/customer/orderCancel`, {
      data: {
        orderPartId,
        reason,
        refundMode,
        ...(refundMode === 'BACK_TO_BANK_ACCOUNT' || refundMode === 'BACK_TO_UPI' ? { payoutId } : {}),
      },
    });
  };
};

const getStoreLists = () => {
  return async (dispatch) => {
    return fetchApi(null, `/api/customer/mystores`);
  };
};

const getRefundModes = ({ orderPartId, isCancel, items }) => {
  return async (dispatch) => {
    return fetchApi(null, `/api/customer/get-refund-modes`, {
      data: { orderPartId, isCancel, items },
    });
  };
};

const getStoreDetails = (storeID) => {
  return async (dispatch) => {
    const response = await fetchApi(null, `/api/customer/mystore`, {
      data: { storeID },
    });
    return response?.store || {};
  };
};

const getReturnDetailsAMI = ({ orderPartId, itemCode }) => {
  return async (dispatch) => {
    return await fetchApi(null, '/api/customer/get-return-details-ami', {
      data: {
        orderPartId,
        itemCode,
      },
    });
  };
};

const initiateReturnForAMI = ({
  backwardFlowType,
  quantity,
  reasonCode,
  skuArticleId,
  itemId,
  comments,
  refundMode,
  returnMode,
  sellerName,
  orderPartId,
}) => {
  return async (dispatch) => {
    const response = await fetchApi(null, `/api/customer/createreturn-ami`, {
      data: {
        payload: {
          backwardFlowType,
          isFullRefund: true,
          lines: [
            {
              itemCode: skuArticleId,
              quantity,
              reason: reasonCode,
              articleId: itemId,
            },
          ],
          comments,
          refundMode: backwardFlowType === 'RETURN' ? refundMode : null,
          returnMode,
          sellerName,
        },
        orderPartId,
      },
    });
    return response;
  };
};

const initiateReturn = ({
  isDecathlon,
  selectedReturnOption,
  itemId,
  orderPartId,
  storeSelected,
  carrierId,
  quantity,
  reason,
  subReason,
  comment,
  refundMode,
  returnMode,
  payoutId,
}) => {
  return async (dispatch) => {
    let payload = '';

    payload = {
      carrierId: selectedReturnOption === 'store' ? null : carrierId,
      dropLocationId: selectedReturnOption === 'store' ? storeSelected.id : null,
      lines: [
        {
          itemCode: itemId,
          quantity,
          reason,
          subReason,
          comments: comment,
        },
      ],
      returnMode: selectedReturnOption === 'store' ? 'DROP_AT_STORE' : isDecathlon ? 'PICKUP_FROM_HOME' : returnMode,
      refundMode,
      payoutId,
    };

    return fetchApi(null, `/api/customer/createreturn`, {
      data: {
        payload,
        orderPartId,
      },
    });
  };
};

const getReturnsTrackingDetails = ({ returnId, isDecathlon } = {}) => {
  return async (dispatch) => {
    const response = await fetchApi(
      null,
      isDecathlon ? `/api/customer/returns-tracking` : `/api/customer/returns-tracking-ami`,
      {
        data: {
          returnId,
        },
      },
    );

    if (response?.status) {
      if (response.returnHistory?.returnHistoryResponses) {
        const res = response.returnHistory?.returnHistoryResponses.map((history) => {
          let status = 'success';
          if (history.status === 'failed') {
            status = 'failed';
          } else if (history.status === 'nil') {
            status = 'nil';
          } else if (history.status === 'inProcess') {
            status = 'inProcess';
          }

          return {
            title: history.returnStatus,
            subStatuses: history?.statusInfo?.map((statusInfo) => {
              const result = {
                status: statusInfo.subStatus || 'Success',
                description: statusInfo.info,
              };

              if (statusInfo.wheresMyRefundFlag === 'true') {
                result.wheresMyRefundFlag = statusInfo.wheresMyRefundFlag;
              }
              if (statusInfo.knowMoreFlag === 'true') {
                result.knowMoreFlag = statusInfo.knowMoreFlag;
              }
              if (statusInfo.courierPartner && statusInfo.courierPartner !== 'Not Available') {
                result.awbNumber = statusInfo.awbNumber;
              }

              if (statusInfo.courierPartner) {
                result.courierPartner = statusInfo.courierPartner;
              }
              if (statusInfo.arn) {
                result.arn = statusInfo.arn;
              }

              return result;
            }),
            status,
            date: getCorrectDate(history.statusUpdatedDate, true),
          };
        });

        return res;
      } else {
        return [];
      }
    } else {
      return { status: false };
    }
  };
};

const getTextDetailsFromWcms = () => {
  return async (dispatch) => {
    //	dispatch({ type: 'ORDER_TRACKING_LOADER', payload: true });
    const contentKey = ['return_reason', 'drop_at_store_notice', 'return_pickup_notice', 'return_within'].join(',');
    const text = await getContentfulClientEntries({
      content_type: appSettingContent.type,
      [appSettingContent.key]: contentKey,
      select: appSettingContent.select,
    });
    if (text?.sys?.type && text?.sys.type !== 'Error' && text?.items?.length > 0) {
      const data = text.items.map((ele) => ({ ...ele.fields }));
      dispatch({ type: 'SET_ORDER_DETAILS_TEXT', payload: data });
    }
  };
};

const getSellersReturnInfoFromWcms = (sellerIds) => {
  return async (dispatch) => {
    const sellerIdsString = sellerIds.join(',');
    const response = await getContentfulClientEntries({
      content_type: sellerInformationContent.type,
      include: 7,
      [`${sellerInformationContent.key}[in]`]: sellerIdsString,
    });
    if (response?.sys?.type && response?.sys.type !== 'Error' && response?.items?.length > 0) {
      const data = response.items.map((ele) => {
        const { sellerId, exchangePolicyDisclaimer, returnSummary, returnPolicy } = ele?.fields || {};
        return { sellerId, exchangePolicyDisclaimer, returnSummary, returnPolicy };
      });

      dispatch({ type: 'SET_SELLERS_RETURN_INFO', payload: data });
    }
  };
};

const myAccountPage = {
  getDigitalOrders,
  getStoreOrders,
  getOrderTrackDetails,
  checkReturnService,
  getCancelReasons,
  cancelOrder,
  getStoreLists,
  getStoreDetails,
  initiateReturn,
  getRefundModes,
  getReturnsTrackingDetails,
  getTextDetailsFromWcms,
  getSellersReturnInfoFromWcms,
  initiateReturnForAMI,
  getReturnDetailsAMI,
  getEmployeeDiscountDetails,
};

export default myAccountPage;
